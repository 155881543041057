import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import cities from '../city.list.json';
import { getDistance } from 'geolib';
import { get } from 'https';

interface calcProps{

}

interface cityObject{
    id: number,
    name: string,
    coord: {
        lon: number,
        lat: number
      }
    country: string
}

const Calculator = (props: calcProps) => {

    // let varLength = 5.5;
    // let varLube = 1;
    // let varSpeed = 1;
    
    const cityArray:cityObject[] = cities as cityObject[];

    const [varLength,setVarLength] = useState({value: 5.5, label: "Regular"});
    const [varLube,setVarLube] = useState({value: 1, label: "Water-based"});
    const [varSpeed,setVarSpeed] = useState({value: 1, label: "Medium"});
    
    // const [varDistance,setVarDistance] = useState(1000);
    const [citySearchedA, setCitySearchedA] = useState("Bucharest");
    const [citySearchedB, setCitySearchedB] = useState("New York");

    const [cityAIndex, setCityAIndex] = useState(16105);
    const [cityBIndex, setCityBIndex] = useState(155102);

    const lengthOptions = [
        { value: 3, label: 'Tiny' },
        { value: 4.25, label: 'Small' },
        { value: 5.5, label: 'Regular' },
        { value: 6.75, label: 'Big' },
        { value: 8, label: 'Huge' },
        { value: 9, label: 'Big Dragon' },
        { value: 10, label: 'Shrek' },
    
    ];

    const lubeOptions = [
        { value: 1, label: 'Water-based' },
        { value: 1.1, label: 'Silicon-based' },
        { value: 1.2, label: 'Oil-based' },
        
    ];

    const speedOptions = [
        { value: 0.75, label: 'Slow' },
        { value: 1, label: 'Medium' },
        { value: 1.25, label: 'Fast' },
        { value: 1.5, label: 'Turbo' },

    ];


    const typeCityA = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCitySearchedA( e.currentTarget.value );
        console.log(`searched city:`, citySearchedA);
    };

    const typeCityB = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCitySearchedB( e.currentTarget.value );
        console.log(`searched city:`, citySearchedA);
    };

    const searchCityA = (searchedText: string) =>{
        var foundCityIndex = cityArray.findIndex(function(city: cityObject, index:number) {
                // debugger;
                //console.log("city: ",city.name)
                    if(city.name.toLowerCase() === searchedText.toLowerCase())
                    return true;
            });

        if (foundCityIndex !== -1){
            setCityAIndex(foundCityIndex);
        }else{
            //nu ma intereseaza eyy
        }
        console.log("found city A index: ",foundCityIndex)
    }

    const searchCityB = (searchedText: string) =>{
        var foundCityIndex = cityArray.findIndex(function(city: cityObject, index:number) {
                // debugger;
                //console.log("city: ",city.name)
                    if(city.name.toLowerCase() === searchedText.toLowerCase())
                    return true;
            });

        if (foundCityIndex !== -1){
            setCityBIndex(foundCityIndex);
        }else{
            //nu ma intereseaza eyy
        }
        console.log("found city B index: ",foundCityIndex)

    }

    const calculateDistance = (ax:number, ay:number, bx:number, by:number) =>{
        let distInMeters = getDistance(
            {latitude:ax,
            longitude:ay},

            {latitude:bx,
            longitude:by}
        );

        let distInKm = distInMeters / 1000;

        // setVarDistance(distInKm);
        
        return(
            Math.round(distInKm)
        )
    }

    const distance = useMemo(() => calculateDistance(
        cityArray[cityAIndex].coord.lat, 
        cityArray[cityAIndex].coord.lon,
        cityArray[cityBIndex].coord.lat,
        cityArray[cityBIndex].coord.lon
        ), [cityArray[cityAIndex].coord.lat, cityArray[cityBIndex].coord.lat]);


    const handleLength = (value: any) => {
        setVarLength(value);
        console.log(`Length selected:`, value);
    }

    const handleLube = (value: any) => {
        setVarLube(value);
        console.log(`Lube selected:`, value);
    }

    const handleSpeed = (value: any) => {
        setVarSpeed(value);
        console.log(`Speed selected:`, value);
    }

    const calculateRamming = (length: number, lube: number, speed: number, dist: number) => {
        
        let min = 0;
        min = (dist / (length * lube * speed) / 50);
        min = Math.round((min + Number.EPSILON) * 10) / 10;

        if(min < 1){
            min = 1;
        }

        return(
            min
        )
    }

    return(
        <div>
            <div className="cityCardContainer">
                <div className="cityCard">
                    <h5>Start Point</h5><br></br>
                    <div className="cityInput">
                        <input type="text" value={citySearchedA} onChange={typeCityA} />
                        <button onClick={() => searchCityA(citySearchedA)}>Submit</button>
                    </div>
                    <div>
                        <h4>
                        {cityArray[cityAIndex].name}, 
                        {cityArray[cityAIndex].country}
                        </h4>
                        <ul className="coords">
                            <li>{cityArray[cityAIndex].coord.lat}</li>
                            <li>{cityArray[cityAIndex].coord.lon}</li>
                        </ul>
                        
                    </div>
                </div>
        
                <div className="cityCard">
                    <h5>End Point</h5><br></br>
                    <div className="cityInput">
                        <input type="text" value={citySearchedB} onChange={typeCityB} />
                        <button onClick={() => searchCityB(citySearchedB)}>Submit</button>
                    </div>
                    <div>
                        <h4>
                        {cityArray[cityBIndex].name},
                        {cityArray[cityBIndex].country}
                        </h4>
                        <ul className="coords">
                            <li>{cityArray[cityBIndex].coord.lat}</li>
                            <li>{cityArray[cityBIndex].coord.lon}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section">
                <h4>Distance </h4>
                {calculateDistance(
                    cityArray[cityAIndex].coord.lat, 
                    cityArray[cityAIndex].coord.lon,
                    cityArray[cityBIndex].coord.lat,
                    cityArray[cityBIndex].coord.lon)
                }
                Km.
            </div>

            <div className="section"><h4>Parameters</h4>

            <div className="input">
                Length of partner's member:
                    <Select options={lengthOptions} onChange={handleLength}/>
            </div>

            <div className="input">
                Lubrication:
                    <Select options={lubeOptions} onChange={handleLube}/>
            </div>

            <div className="input">
                Penetration speed:
                    <Select options={speedOptions} onChange={handleSpeed}/>
            </div>

            </div>

            <div className="section"><h4>Result</h4>
            Your travel will require around <span>{calculateRamming(varLength.value, varLube.value, varSpeed.value, distance)} minute(s)</span> of {varSpeed.label} speed ramming by a {varLength.label}-sized penis.
            </div>
        </div>
    )
} 

export default Calculator;