import React from 'react';
import logo from './logo.svg';
import './App.css';
import Calculator from './Components/Calculator';

function App() {
  return (
    <div className="App">
      <div>
        Welcome to
        <h2>ATS (Anal Teleportation Services)</h2>
        <h4>In your ass and off your ass!</h4>
        <p>The ATS allows for swift and safe transportation across the globe through teleportation, requiring only a session of anal sex as a form of payment. Please select the locations for departure and destination and input your preferred parameters for the session. </p>
          <p>ATS wishes you a pleasant travel and happy memories! </p>
      </div>
      <Calculator/>
      <div className="disclaimer">
        This service is currently in development. If you can't find a city, try searching for the closest major city nearest your initial choice instead. 
      </div>
    </div>
  );
}

export default App;
